#modal {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 3;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#helpBox {
  height: fit-content;
  width: 50%;
  background-color: #fdfbf7;
  color: black;
  border: ridge navy 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  -webkit-user-select: none;
  user-select: none;
}

#helpBox section {
  width: 90%;
}

#helpBox h4 {
  margin-bottom: 0;
  /* text-decoration: underline; */
  border-bottom: 2px solid black;
  width: fit-content;
}

#helpBox button {
  background-color: navy;
  color: #fdfbf7;
}

#helpBox button:hover {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 128, 0.5);
}

#helpBox button:active {
  position: relative;
  top: 1px;
  left: 1px;
}
