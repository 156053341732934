@font-face {
  font-family: "Stam";
  src: url(../fonts/StamAshkenazCLM-Medium.otf);
}

@font-face {
  font-family: "David";
  src: url(../fonts/DavidLibre-Medium.ttf);
}

/* @font-face {
    font-family: "Frank-Ruhl";
    src: url(../fonts/FrankRuhlLibre-Regular.ttf);
} */

#textContainer {
  grid-column: 5 / 6;
  overflow-y: auto;
  grid-row: 2 / 4;
  scrollbar-width: none;
  position: relative;
  padding-left: 8px;
}

#innerTextContainer {
  white-space: pre-wrap;
  text-align: justify;
  direction: rtl;
  overflow-y: visible;
  /* font-size: 3.5vw; */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fdfbf7;
  text-align-last: justify;
  display: flex;
  flex-direction: column;
}

#innerTextContainer h2 {
  margin: 0;
  text-align: right;
  width: fit-content;
}

.navButton {
  text-align: center;
  text-align-last: center;
  width: fit-content;
  align-self: center;
  background-color: #fdfbf7;
  color: black;
  text-decoration: none;
  border: 1px black solid;
  border-radius: 28px;
  padding: 16px 20px;
  color: black;
  font-size: 3vh;
  cursor: pointer;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.navButton:hover {
  /* background-color: beige; */
  border-width: 2px;
}

.navButton:active {
  position: relative;
  top: 1px;
  left: 1px;
}

.navButton:first-child {
  margin-bottom: 20px;
}

.navButton:first-child:hover {
  margin-bottom: 17.5px;
}

.navButton:last-child {
  margin-top: 25px;
}

@media screen and (max-width: 500px) {
  #textContainer {
    grid-row-start: 3;
    grid-column: 2 / 8;
  }

  /*  #innerTextContainer {
        font-size: 4vh;
    } */
}
