#loadError {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-color: #fdfbf7;
  color: navy;
}

#loadError span {
  font-size: 8vh;
  text-align: center;
}

#toHome {
  border-radius: 28px;
  padding: 16px 20px;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: navy;
  color: #fdfbf7;
  text-decoration: none;
}

#toHome:hover {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 128, 0.5);
}

#toHome:active {
  position: relative;
  top: 1px;
  left: 1px;
}
